/* Normalize
*************/
@import url(/bower_components/normalize.css/normalize.css);
/* Fonts
*************/
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Roboto:300,400,500,700';
/* Vendors
*************/
button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #2f68d4;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.675em 1.35em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, [type='button']:hover, [type='button']:focus, [type='reset']:hover, [type='reset']:focus, [type='submit']:hover, [type='submit']:focus {
    background-color: #2653aa;
    color: #fff; }
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
      background-color: #2f68d4; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.3375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.3375em; }

input,
select,
textarea {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px; }

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea,
select[multiple] {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.675em;
  padding: 0.45em;
  transition: border-color 150ms ease;
  width: 100%; }
  [type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: #b1b1b1; }
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #2f68d4;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(40, 93, 193, 0.7);
    outline: none; }
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #ddd; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.3375em; }

[type="file"] {
  margin-bottom: 0.675em;
  width: 100%; }

select {
  margin-bottom: 0.675em;
  max-width: 100%;
  width: auto; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 0.675em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.675em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.675em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: #525355;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.35; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.675em; }

p {
  margin: 0 0 0.675em; }

a {
  color: #2f68d4;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #234e9f; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.35em 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/* Core
*************/
body {
  transition: top 0.3s ease; }
  body.no-scroll {
    overflow: hidden; }

.container {
  max-width: 81.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 5%; }
  .container::after {
    clear: both;
    content: "";
    display: block; }

@media screen and (min-width: 48rem) {
  .special-container {
    max-width: 81.25rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5%; }
    .special-container::after {
      clear: both;
      content: "";
      display: block; }
    .special-container::after {
      clear: both;
      content: "";
      display: block; } }

/* LINKS */
a {
  cursor: pointer; }

/* IMAGE */
img {
  display: block;
  width: 100%; }

/* FADE OUT ON HOVER */
.fade-out {
  opacity: 1;
  transition: 0.4s; }
  .fade-out:hover {
    opacity: 0.8; }

/* GLOBALS
***********/
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  color: #525355;
  font-family: "Helvetica Neue", Helvetica, sans-serif; }

h1, h2, h3, h4, h5, h6 {
  color: #00529b; }

h1 {
  font-size: 1.9rem; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    h1 {
      font-size: 2.4rem; } }
  @media screen and (min-width: 60rem) {
    h1 {
      font-size: 3.2rem; } }

h1.bottom-border {
  border-bottom: 3px solid #c4bdb6;
  padding-bottom: 10px; }

h2 {
  font-size: 1.8rem;
  text-align: center; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    h2 {
      font-size: 2.1rem; } }
  @media screen and (min-width: 60rem) {
    h2 {
      font-size: 3.2rem;
      text-align: center; } }

h4 {
  font-size: 1.4rem;
  font-weight: bold; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    h4 {
      font-size: 1.6rem; } }
  @media screen and (min-width: 60rem) {
    h4 {
      font-size: 2.2rem; } }

p {
  font-size: 1.2rem; }
  @media screen and (min-width: 60rem) {
    p {
      font-size: 1.5rem; } }

a {
  color: #2f68d4;
  font-size: 1.1rem; }
  a:hover {
    color: #2f68d4; }

/* HOMEPAGE HEADER
*******************/
.top-navigation {
  text-align: right; }
  .top-navigation a {
    color: #ffffff;
    font-size: 1.1rem; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .top-navigation a {
        font-size: 1.2rem; } }
    @media screen and (min-width: 60rem) {
      .top-navigation a {
        font-size: 1.3rem; } }

.main-nav {
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .main-nav ul {
      text-align: right; } }
  .main-nav li a {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 1px; }
    @media screen and (min-width: 48rem) {
      .main-nav li a {
        color: #134979; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .main-nav li a {
        font-size: 1.1rem; } }
    @media screen and (min-width: 60rem) {
      .main-nav li a {
        font-size: 1.4rem; } }

.hero-text h1 {
  color: #ffffff;
  text-align: center; }

/* HOMEPAGE BODY
*******************/
#services-offered .container p {
  color: #525355;
  text-align: center; }

#services-offered .container a {
  color: #525355;
  font-size: 1.2rem;
  text-decoration: underline; }
  @media screen and (min-width: 60rem) {
    #services-offered .container a {
      font-size: 1.5rem; } }
  #services-offered .container a:hover {
    text-decoration: none; }

.service-types h4 a {
  color: #134979;
  font-size: 1.4rem;
  font-weight: bold; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .service-types h4 a {
      font-size: 1.6rem; } }
  @media screen and (min-width: 60rem) {
    .service-types h4 a {
      font-size: 2.2rem; } }

.service-types p {
  color: #777777;
  line-height: 1.25; }

@media screen and (min-width: 48rem) and (max-width: 59.94rem) {
  .service-types a.learn-more {
    font-size: 1.2rem; } }

@media screen and (min-width: 60rem) {
  .service-types a.learn-more {
    font-size: 1.4rem; } }

.service-types a.learn-more:hover {
  text-decoration: underline; }

#which-lifestage .lifestage-content h2 {
  font-size: 1.8rem;
  text-align: center; }
  @media screen and (min-width: 48rem) {
    #which-lifestage .lifestage-content h2 {
      text-align: left; } }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    #which-lifestage .lifestage-content h2 {
      font-size: 2.2rem; } }
  @media screen and (min-width: 60rem) {
    #which-lifestage .lifestage-content h2 {
      font-size: 3.4rem; } }

#which-lifestage .lifestage-content p {
  text-align: center; }
  @media screen and (min-width: 48rem) {
    #which-lifestage .lifestage-content p {
      text-align: left; } }

#which-lifestage .lifestage-content a {
  color: #525355;
  font-size: 1.2rem;
  text-decoration: underline; }
  @media screen and (min-width: 60rem) {
    #which-lifestage .lifestage-content a {
      font-size: 1.5rem; } }
  #which-lifestage .lifestage-content a:hover {
    text-decoration: none; }

#which-lifestage .lifestages {
  text-align: center; }
  @media screen and (min-width: 48rem) {
    #which-lifestage .lifestages {
      text-align: left; } }
  #which-lifestage .lifestages p {
    color: #00529b;
    font-size: 1.4rem;
    font-weight: bold; }
    @media screen and (min-width: 60rem) {
      #which-lifestage .lifestages p {
        font-size: 1.9rem; } }
  #which-lifestage .lifestages a {
    transition: all 0.2s; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #which-lifestage .lifestages a {
        font-size: 1.1rem; } }
    @media screen and (min-width: 60rem) {
      #which-lifestage .lifestages a {
        font-size: 1.5rem; } }
    #which-lifestage .lifestages a:hover {
      text-decoration: underline; }

.contact-footer {
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .contact-footer {
      text-align: left; } }
  .contact-footer h2 {
    font-size: 1.8rem; }
    @media screen and (min-width: 48rem) {
      .contact-footer h2 {
        text-align: left; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .contact-footer h2 {
        font-size: 2.2rem; } }
    @media screen and (min-width: 60rem) {
      .contact-footer h2 {
        font-size: 3.2rem; } }
  .contact-footer p {
    color: #ffffff; }
  .contact-footer a {
    color: #ffffff;
    text-decoration: underline; }
    @media screen and (min-width: 60rem) {
      .contact-footer a {
        font-size: 1.5rem; } }
    .contact-footer a:hover {
      text-decoration: none; }
    .contact-footer a.blue-button {
      text-decoration: none; }

/* HOMEPAGE FOOTER
*******************/
.footer-homepage {
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .footer-homepage {
      text-align: left; } }
  .footer-homepage .newsletter h3 {
    font-size: 1.4rem; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .footer-homepage .newsletter h3 {
        font-size: 1.7rem; } }
    @media screen and (min-width: 60rem) {
      .footer-homepage .newsletter h3 {
        font-size: 1.9rem; } }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .footer-homepage .newsletter p {
      font-size: 1.1rem; } }
  @media screen and (min-width: 60rem) {
    .footer-homepage .newsletter p {
      font-size: 1.3rem;
      font-weight: 300;
      letter-spacing: -1px; } }
  @media screen and (min-width: 60rem) {
    .footer-homepage .newsletter a {
      font-size: 1.4rem; } }
  .footer-homepage .footer-address {
    text-align: center; }
    @media screen and (min-width: 48rem) {
      .footer-homepage .footer-address {
        text-align: left; } }
    .footer-homepage .footer-address p {
      font-size: 0.9rem; }
      @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
        .footer-homepage .footer-address p {
          font-size: 1rem; } }
      @media screen and (min-width: 60rem) {
        .footer-homepage .footer-address p {
          line-height: 1.25;
          font-size: 1.3rem; } }

/* Components
*************/
header .main-heading {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.5); }
  @media screen and (min-width: 60rem) {
    header .main-heading {
      box-shadow: none; } }

header .top-navigation {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 61.6158806508%;
  margin-right: 0;
  margin-bottom: 10px;
  padding-top: 15px; }
  header .top-navigation:last-child {
    margin-right: 0; }
  @media screen and (min-width: 48rem) {
    header .top-navigation {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 97.4410587101%;
      margin-right: 0; }
      header .top-navigation:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 60rem) {
    header .top-navigation {
      margin-bottom: 5px; } }
  header .top-navigation a.header-contact {
    padding: 7px 10px;
    transition: 0.3s; }
    @media screen and (min-width: 30rem) {
      header .top-navigation a.header-contact {
        padding: 7px 16px; } }
  header .top-navigation a.header-login {
    margin-right: 5px;
    padding: 7px 10px;
    transition: 0.3s; }
    @media screen and (min-width: 30rem) {
      header .top-navigation a.header-login {
        padding: 7px 16px; } }

header .logo {
  clear: both;
  padding: 20px 0 30px; }
  @media screen and (min-width: 48rem) {
    header .logo {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%;
      margin-right: 0;
      padding-top: 5px; }
      header .logo:last-child {
        margin-right: 0; } }
  header .logo h1 {
    margin: 0 auto; }
    @media screen and (min-width: 20rem) and (max-width: 47.94rem) {
      header .logo h1 {
        width: 220px; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      header .logo h1 {
        width: 180px; } }
    @media screen and (min-width: 60rem) {
      header .logo h1 {
        margin: 0;
        width: 240px; } }
  header .logo a {
    cursor: pointer;
    display: block; }

header .hero-text h1 {
  margin: 0;
  padding: 160px 0; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    header .hero-text h1 {
      padding: 160px 6%; } }
  @media screen and (min-width: 60rem) {
    header .hero-text h1 {
      padding: 170px 10% 120px; } }

header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px; }
  @media screen and (min-width: 60rem) {
    header {
      height: 700px; } }

.home header {
  height: 750px; }
  @media screen and (min-width: 60rem) {
    .home header {
      height: 720px; } }

/* MOBILE MENU TOGGLE*/
.menu-toggle {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 36.0264677513%;
  color: #134979;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 14px 0 0 0;
  text-transform: uppercase; }
  .menu-toggle:last-child {
    margin-right: 0; }
  .menu-toggle:hover {
    color: #134979; }
  @media screen and (min-width: 48rem) {
    .menu-toggle {
      display: none; } }

span.menu {
  display: none;
  margin-left: 10px;
  line-height: 30px; }
  @media screen and (min-width: 20rem) {
    span.menu {
      display: inline-block; } }

/* MOBILE NAVIGATION */
.mobile-nav {
  background: #103c63;
  display: none;
  padding: 0;
  width: 100%; }
  @media screen and (min-width: 48rem) {
    .mobile-nav {
      display: none; } }
  .mobile-nav li:last-of-type {
    border-bottom: none;
    margin-right: 0; }
  .mobile-nav li:hover {
    background: #134979; }
  .mobile-nav .nav-link {
    border-bottom: 1px dotted #092137; }
  .mobile-nav .link-nav {
    color: #ffffff;
    display: inline-block;
    outline: 0;
    padding: 20px 2% 20px 5%;
    position: relative;
    text-align: center; }
  .mobile-nav .nav-dropdown {
    background: #0d3456;
    display: none; }
    .mobile-nav .nav-dropdown a {
      border-bottom: 1px dotted #092137;
      color: #ffffff;
      display: block;
      outline: 0;
      padding: 20px 5%;
      text-align: left; }
  .mobile-nav .dropdown-arrow {
    color: #ffffff;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 10px 20px 10px 10px; }
    @media screen and (min-width: 48rem) {
      .mobile-nav .dropdown-arrow {
        display: none; } }
    .mobile-nav .dropdown-arrow:focus {
      outline: none; }

/* DESKTOP NAVIGATION */
.main-nav {
  display: none; }
  @media screen and (min-width: 48rem) {
    .main-nav {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 74.4105871005%;
      margin-right: 0;
      background: transparent;
      display: block; }
      .main-nav:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .main-nav {
      padding-top: 34px; } }
  @media screen and (min-width: 60rem) {
    .main-nav {
      padding-top: 45px; } }
  .main-nav li {
    transition: 0.3s; }
    @media screen and (min-width: 48rem) {
      .main-nav li {
        display: inline-block;
        padding: 5px 0;
        position: relative;
        text-align: left; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .main-nav li {
        margin: 0 8px; } }
    @media screen and (min-width: 60rem) and (max-width: 1199px) {
      .main-nav li {
        margin: 0 10px; } }
    @media screen and (min-width: 1200px) {
      .main-nav li {
        margin: 0 16px; } }
    .main-nav li:last-of-type {
      border-bottom: none;
      margin-right: 0; }
    .main-nav li:hover {
      background: #134979; }
      @media screen and (min-width: 48rem) {
        .main-nav li:hover {
          background: transparent; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .main-nav li a {
        font-size: 0.95rem; } }
    @media screen and (min-width: 60rem) and (max-width: 1199px) {
      .main-nav li a {
        font-size: 1.2rem; } }
  @media screen and (min-width: 48rem) {
    .main-nav .link-nav {
      border: none;
      display: block;
      padding: 0 0 2px 0;
      text-align: left; } }
  @media screen and (min-width: 48rem) {
    .main-nav .link-nav::before {
      background-color: #134979;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      transition: all 0.25s ease;
      width: 0%; } }
  .main-nav .link-nav:hover::before {
    width: 100%; }
  @media screen and (min-width: 48rem) {
    .main-nav a.active::before {
      background-color: #134979;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      width: 100%; } }
  @media screen and (min-width: 48rem) {
    .main-nav .nav-dropdown {
      position: absolute;
      top: 42px;
      left: 0;
      background: rgba(255, 255, 255, 0.7);
      display: none;
      text-align: left;
      width: 170px;
      z-index: 1; } }
  .main-nav .nav-dropdown li {
    border-bottom: 1px dotted #092137;
    padding: 20px 0; }
    @media screen and (min-width: 48rem) {
      .main-nav .nav-dropdown li {
        border: none;
        font-size: 1.2rem;
        margin: 0;
        padding: 10px 10px 10px 8px;
        width: 100%; } }
    @media screen and (min-width: 48rem) {
      .main-nav .nav-dropdown li:hover {
        background: white; } }

/* BURGER ICON */
.burger-icon {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 23.2317613015%;
  height: 30px;
  margin: 0 auto;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  width: 30px; }
  .burger-icon:last-child {
    margin-right: 0; }
  @media screen and (min-width: 30rem) {
    .burger-icon {
      width: 35px; } }
  .burger-icon span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 3px;
    background: #134979;
    border-radius: 2px;
    opacity: 1;
    transform: rotate(0deg);
    transition: .2s ease-in-out; }
    .burger-icon span:nth-child(1) {
      top: 10%; }
    .burger-icon span:nth-child(2) {
      top: 36%; }
    .burger-icon span:nth-child(3) {
      top: 36%; }
    .burger-icon span:nth-child(4) {
      top: 62%; }
  .burger-icon.show {
    position: relative;
    transition: background 0.3s;
    z-index: 999; }
    .burger-icon.show span {
      height: 2px; }
      .burger-icon.show span:nth-child(1) {
        top: 43%;
        width: 0%;
        left: 50%; }
      .burger-icon.show span:nth-child(2) {
        transform: rotate(45deg); }
      .burger-icon.show span:nth-child(3) {
        transform: rotate(-45deg); }
      .burger-icon.show span:nth-child(4) {
        top: 43%;
        width: 0%;
        left: 50%; }

.services-lifestages ul {
  padding: 0; }
  @media screen and (min-width: 30rem) {
    .services-lifestages ul {
      padding: 0; }
      .services-lifestages ul::after {
        clear: both;
        content: "";
        display: block; } }

.services-lifestages li {
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.3s; }
  @media screen and (min-width: 30rem) and (max-width: 47.94rem) {
    .services-lifestages li {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%;
      padding-bottom: 20px; }
      .services-lifestages li:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 48rem) {
    .services-lifestages li {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%;
      padding-bottom: 0; }
      .services-lifestages li:last-child {
        margin-right: 0; } }
  .services-lifestages li:nth-child(odd) .lifestage-info {
    background: #afa091; }
  .services-lifestages li:nth-child(even) .lifestage-info {
    background: #c4b8ac; }
  @media screen and (min-width: 30rem) and (max-width: 47.94rem) {
    .services-lifestages li:nth-child(2n) {
      margin-right: 0; } }
  @media screen and (min-width: 48rem) {
    .services-lifestages li:nth-child(4n) {
      margin-right: 0; } }

.services-lifestages .lifestage-wrap > a {
  display: block;
  overflow: hidden; }
  .services-lifestages .lifestage-wrap > a:hover .lifestage-image {
    transform: scale(1.02); }

.services-lifestages .lifestage-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  overflow: hidden;
  transition: 0.4s;
  width: 100%; }

.services-lifestages .lifestage-info {
  padding: 10px 0;
  text-align: center; }
  .services-lifestages .lifestage-info h3 {
    font-size: 1.6rem;
    margin: 0; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .services-lifestages .lifestage-info h3 {
        font-size: 1.3rem; } }
  .services-lifestages .lifestage-info a {
    font-size: 1.2rem; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .services-lifestages .lifestage-info a {
        font-size: 1rem; } }
    @media screen and (min-width: 60rem) {
      .services-lifestages .lifestage-info a {
        font-size: 1.3rem; } }
    .services-lifestages .lifestage-info a:hover {
      text-decoration: underline; }

.in-the-news {
  padding-bottom: 70px; }
  .in-the-news h1 {
    margin-bottom: 30px; }
  .in-the-news p {
    margin-bottom: 20px; }
  .in-the-news .news-links p {
    margin-bottom: 0; }
  .in-the-news .news-links a {
    color: #2892d1;
    font-size: 1.2rem;
    text-decoration: underline; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .in-the-news .news-links a {
        font-size: 1.2rem; } }
    @media screen and (min-width: 60rem) {
      .in-the-news .news-links a {
        font-size: 1.5rem; } }
    .in-the-news .news-links a:hover {
      color: #217aaf; }

.contact-footer {
  background: #9c8c7c;
  padding: 40px 0 45px 0; }
  @media screen and (min-width: 48rem) {
    .contact-footer::after {
      clear: both;
      content: "";
      display: block; } }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .contact-footer {
      padding: 22px 0 25px 0; } }
  @media screen and (min-width: 60rem) {
    .contact-footer {
      padding: 22px 0 25px 0; } }
  .contact-footer .contact-info {
    margin-bottom: 40px; }
    @media screen and (min-width: 48rem) {
      .contact-footer .contact-info {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%;
        margin: 0; }
        .contact-footer .contact-info:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 48rem) {
      .contact-footer .contact-info h2 {
        margin-bottom: 20px; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .contact-footer .contact-info p {
        padding-right: 20px; } }
    @media screen and (min-width: 60rem) {
      .contact-footer .contact-info p {
        padding-right: 60px; } }
  @media screen and (min-width: 48rem) {
    .contact-footer .contact-button {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 29.2026243114%;
      margin-left: 19.6185498896%;
      margin-right: 0;
      padding: 95px 0 0; }
      .contact-footer .contact-button:last-child {
        margin-right: 0; } }
  .contact-footer .contact-button a {
    padding: 8px 27px 9px; }

.footer-homepage {
  background-color: #c4bdb6;
  padding: 50px 0 40px 0; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .footer-homepage {
      padding: 15px 0 40px 0; } }
  @media screen and (min-width: 60rem) {
    .footer-homepage {
      padding: 15px 0 55px 0; } }
  .footer-homepage .newsletter {
    margin-bottom: 60px; }
    @media screen and (min-width: 48rem) {
      .footer-homepage .newsletter {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%;
        margin-right: 0; }
        .footer-homepage .newsletter:last-child {
          margin-right: 0; } }
    .footer-homepage .newsletter h3 {
      margin: 0 0 5px; }
    .footer-homepage .newsletter input {
      appearance: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 40px;
      margin: 0 auto 10px auto;
      padding: 5px 10px;
      text-align: center;
      outline: none;
      width: 220px; }
      @media screen and (min-width: 48rem) {
        .footer-homepage .newsletter input {
          margin: 0 0 10px 0;
          text-align: left; } }
      @media screen and (min-width: 60rem) {
        .footer-homepage .newsletter input {
          width: 260px; } }
      .footer-homepage .newsletter input:focus::-webkit-input-placeholder {
        color: transparent; }
      .footer-homepage .newsletter input::-webkit-input-placeholder {
        transition: 0.3s; }
      .footer-homepage .newsletter input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #f3f3f4 inset;
        -webkit-text-fill-color: #525355 !important; }
    .footer-homepage .newsletter a {
      display: inline-block;
      padding: 4px 14px 4px 14px; }
  @media screen and (min-width: 48rem) {
    .footer-homepage .footer-address {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%;
      margin-left: 17.0596085997%;
      margin-right: 0; }
      .footer-homepage .footer-address:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 48rem) {
    .footer-homepage .footer-address {
      padding: 22px 0 0 0; } }
  .footer-homepage .footer-address p {
    margin-bottom: 5px; }
    @media screen and (min-width: 60rem) {
      .footer-homepage .footer-address p {
        margin-bottom: 0; } }

/* Templates
*************/
#services-offered {
  background-color: #f8f0e8;
  padding: 70px 0 0 0; }
  @media screen and (min-width: 60rem) {
    #services-offered {
      padding: 90px 0 0 0; } }
  #services-offered .container h2 {
    margin-bottom: 30px; }
  #services-offered .container p {
    margin-bottom: 70px; }
    @media screen and (min-width: 48rem) {
      #services-offered .container p {
        margin-bottom: 80px; } }

.service-types {
  padding: 0 0 30px; }
  @media screen and (min-width: 30rem) and (max-width: 47.94rem) {
    .service-types {
      padding: 0 0 40px; } }
  @media screen and (min-width: 48rem) {
    .service-types {
      padding: 0; }
      .service-types::after {
        clear: both;
        content: "";
        display: block; } }
  .service-types .service-image {
    margin-bottom: 20px; }
    @media screen and (min-width: 30rem) and (max-width: 47.94rem) {
      .service-types .service-image {
        margin-bottom: 30px; } }
    @media screen and (min-width: 48rem) {
      .service-types .service-image {
        margin-right: 0;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 0; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .service-types .service-image {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 40.2913699012%;
        height: 275px; }
        .service-types .service-image:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 60rem) {
      .service-types .service-image {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 31.7615656014%;
        height: 275px; }
        .service-types .service-image:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 48rem) {
      .service-types .service-image img {
        display: none; } }
  .service-types .service-info {
    padding: 10px 5%; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .service-types .service-info {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%;
        margin-right: 0;
        padding: 45px 0 30px 7%; }
        .service-types .service-info:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 60rem) {
      .service-types .service-info {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 65.8807828007%;
        margin-right: 0;
        padding: 55px 0 50px 9%; }
        .service-types .service-info:last-child {
          margin-right: 0; } }
    .service-types .service-info h4 {
      margin-bottom: 5px; }
      @media screen and (min-width: 48rem) {
        .service-types .service-info h4 {
          margin-bottom: 0; } }
      .service-types .service-info h4 a {
        transition: 0.3s; }
        .service-types .service-info h4 a:hover {
          opacity: 0.85; }
    .service-types .service-info p {
      margin-bottom: 5px; }
      @media screen and (min-width: 48rem) {
        .service-types .service-info p {
          margin-bottom: 0; } }

.bg-1 {
  background: #d7cec4; }

.bg-2 {
  background: #c4b8ac; }

.bg-3 {
  background: #afa091; }

#which-lifestage {
  margin: 0;
  padding: 60px 0 30px 0; }
  @media screen and (min-width: 48rem) {
    #which-lifestage {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      #which-lifestage::after {
        clear: both;
        content: "";
        display: block; } }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    #which-lifestage {
      padding: 40px 0 20px 0; } }
  @media screen and (min-width: 60rem) {
    #which-lifestage {
      padding: 56px 0 33px 0; } }
  #which-lifestage .lifestage-content {
    margin-bottom: 40px; }
    @media screen and (min-width: 48rem) {
      #which-lifestage .lifestage-content {
        float: left;
        display: block;
        margin-right: 2.3576515979%;
        width: 48.821174201%;
        margin: 0; }
        #which-lifestage .lifestage-content:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #which-lifestage .lifestage-content {
        padding: 20px 0; } }
    @media screen and (min-width: 60rem) {
      #which-lifestage .lifestage-content {
        padding: 40px 0; } }
    #which-lifestage .lifestage-content h2 {
      margin: 0 0 20px 0; }
  @media screen and (min-width: 48rem) {
    #which-lifestage .lifestages {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 31.7615656014%;
      margin-left: 17.0596085997%;
      margin-right: 0; }
      #which-lifestage .lifestages:last-child {
        margin-right: 0; } }
  #which-lifestage .lifestages li {
    margin-bottom: 15px; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #which-lifestage .lifestages li {
        margin-bottom: 25px; } }
    @media screen and (min-width: 60rem) {
      #which-lifestage .lifestages li {
        margin-bottom: 40px; } }
    @media screen and (min-width: 48rem) {
      #which-lifestage .lifestages li:last-of-type {
        margin-bottom: 0; } }
  #which-lifestage .lifestages p {
    margin-bottom: 0;
    line-height: 1; }

.two-column {
  background-color: #f8f0e8; }

@media screen and (min-width: 48rem) {
  .content-wrap::after {
    clear: both;
    content: "";
    display: block; } }

@media screen and (min-width: 48rem) {
  .page-info {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 44.5562720511%; }
    .page-info:last-child {
      margin-right: 0; } }

@media screen and (min-width: 48rem) {
  .additional-info {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 44.5562720511%;
    margin-left: 8.5298042998%;
    margin-right: 0; }
    .additional-info:last-child {
      margin-right: 0; } }

.about {
  background-color: #f8f0e8; }

#about-message {
  padding: 85px 0 90px 0; }
  #about-message h2 {
    font-size: 1.5rem;
    margin-bottom: 50px;
    text-align: center; }
    @media screen and (min-width: 30rem) and (max-width: 47.94rem) {
      #about-message h2 {
        font-size: 1.6rem; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #about-message h2 {
        font-size: 2.1rem; } }
    @media screen and (min-width: 60rem) {
      #about-message h2 {
        font-size: 3.2rem; } }
  #about-message p {
    margin-bottom: 50px;
    text-align: center; }
    #about-message p:last-of-type {
      margin-bottom: 0; }
    #about-message p a {
      color: #525355;
      font-size: 1.2rem;
      text-decoration: underline; }
      @media screen and (min-width: 60rem) {
        #about-message p a {
          font-size: 1.5rem; } }
      #about-message p a:hover {
        text-decoration: none; }

#our-team h1 {
  margin-bottom: 50px; }

@media screen and (min-width: 48rem) {
  #our-team .team-members::after {
    clear: both;
    content: "";
    display: block; } }

#our-team li {
  margin-bottom: 60px; }
  @media screen and (min-width: 48rem) {
    #our-team li {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 45.4092524811%;
      margin: 0 9% 50px 0; }
      #our-team li:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 48rem) {
    #our-team li:nth-of-type(2n) {
      margin-right: 0; } }
  #our-team li:nth-of-type(odd) {
    clear: left; }

#our-team figure {
  margin-bottom: 15px; }
  @media screen and (min-width: 48rem) {
    #our-team figure {
      margin-bottom: 20px; } }
  #our-team figure img {
    margin-bottom: 30px; }
    @media screen and (min-width: 48rem) {
      #our-team figure img {
        margin-bottom: 40px; } }
  #our-team figure figcaption {
    color: #134979;
    font-size: 1.5rem;
    font-weight: bold; }
    @media screen and (min-width: 48rem) {
      #our-team figure figcaption {
        font-size: 1.8rem; } }

#our-team .member-info {
  font-size: 1.2rem; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    #our-team .member-info {
      font-size: 1.2rem; } }
  @media screen and (min-width: 60rem) {
    #our-team .member-info {
      font-size: 1.5rem; } }
  #our-team .member-info ul {
    list-style: disc; }
  #our-team .member-info li {
    display: list-item;
    float: none;
    margin-bottom: 10px;
    margin-left: 20px; }
  #our-team .member-info a {
    color: #525355;
    font-size: 1.2rem;
    text-decoration: underline; }
    @media screen and (min-width: 60rem) {
      #our-team .member-info a {
        font-size: 1.5rem; } }
    #our-team .member-info a:hover {
      text-decoration: none; }

#our-team .admin-team {
  margin-bottom: 80px; }
  @media screen and (min-width: 48rem) {
    #our-team .admin-team::after {
      clear: both;
      content: "";
      display: block; } }
  @media screen and (min-width: 48rem) {
    #our-team .admin-team li {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 23.2317613015%;
      margin-bottom: 0; }
      #our-team .admin-team li:last-child {
        margin-right: 0; } }
  #our-team .admin-team li:nth-of-type(odd) {
    clear: none; }
  #our-team .admin-team li figure img {
    margin-bottom: 10px; }
  #our-team .admin-team li figure figcaption p {
    margin-bottom: 2px; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #our-team .admin-team li figure figcaption p {
        font-size: 1rem; } }
    @media screen and (min-width: 60rem) {
      #our-team .admin-team li figure figcaption p {
        font-size: 1.2rem; } }
  #our-team .admin-team li figure figcaption .job-title {
    font-size: 1rem;
    font-weight: normal; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      #our-team .admin-team li figure figcaption .job-title {
        font-size: 0.9rem; } }

.services .services-wrap {
  background: #f8f0e8; }

.services .intro-text {
  padding: 70px 0 50px 0; }
  @media screen and (min-width: 60rem) {
    .services .intro-text {
      padding-top: 90px; } }
  .services .intro-text p {
    text-align: center; }

.services .services-options .container h1 {
  margin-bottom: 20px; }
  @media screen and (min-width: 48rem) {
    .services .services-options .container h1 {
      margin-bottom: 40px;
      padding-bottom: 15px; } }

.services .services-options .container p {
  margin-bottom: 80px; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .services .services-options .container p {
      margin-bottom: 120px; } }
  @media screen and (min-width: 60rem) {
    .services .services-options .container p {
      margin-bottom: 140px; } }

.services .services-options ul {
  padding-bottom: 80px; }
  @media screen and (min-width: 48rem) {
    .services .services-options ul {
      padding-bottom: 130px; } }

@media screen and (min-width: 48rem) {
  .services .services-options .service-types {
    margin-bottom: 17px; } }

@media screen and (min-width: 48rem) {
  .services .services-options .service-types:last-of-type {
    margin-bottom: 0; } }

@media screen and (min-width: 48rem) {
  .services .services-options .service-image {
    margin-right: 0; } }

@media screen and (min-width: 48rem) and (max-width: 59.94rem) {
  .services .services-options .service-info {
    padding: 20px 0 40px 8%; } }

@media screen and (min-width: 60rem) {
  .services .services-options .service-info {
    padding: 10px 0 70px 8%; } }

.single-service {
  background-color: #f8f0e8;
  padding: 70px 0 150px; }
  .single-service .single-info {
    padding-bottom: 175px;
    font-size: 1.2rem; }
    @media screen and (min-width: 60rem) {
      .single-service .single-info {
        font-size: 1.5rem; } }
    .single-service .single-info p {
      margin-bottom: 40px; }
    .single-service .single-info a {
      color: #525355;
      font-size: 1.2rem;
      text-decoration: underline; }
      @media screen and (min-width: 60rem) {
        .single-service .single-info a {
          font-size: 1.5rem; } }
      .single-service .single-info a:hover {
        text-decoration: none; }
    .single-service .single-info ul {
      list-style-type: disc;
      margin-left: 25px; }
      .single-service .single-info ul li {
        margin-bottom: 20px; }
        .single-service .single-info ul li:last-child {
          margin-bottom: 25px; }

.lifestages h1.page-heading, .midcareer h1.page-heading {
  margin-bottom: 35px;
  padding-top: 70px; }

.lifestages .content-wrap, .midcareer .content-wrap {
  padding-bottom: 20px; }

.lifestages .page-info, .midcareer .page-info {
  margin-bottom: 50px; }

.lifestages .bullet:before, .midcareer .bullet:before {
  content: "\2022";
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle; }

.lifestages .faqs li, .midcareer .faqs li {
  margin-bottom: 40px; }

.lifestages .faqs h4, .midcareer .faqs h4 {
  color: #afa091;
  margin-bottom: 0; }

.lifestages .faqs p, .midcareer .faqs p {
  margin-bottom: 0; }

.lifestages .services-lifestages, .midcareer .services-lifestages {
  padding-bottom: 30px; }
  @media screen and (min-width: 48rem) {
    .lifestages .services-lifestages ul li, .midcareer .services-lifestages ul li {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 29.2026243114%;
      margin-right: 5%; }
      .lifestages .services-lifestages ul li:last-child, .midcareer .services-lifestages ul li:last-child {
        margin-right: 0; } }
  @media screen and (min-width: 48rem) {
    .lifestages .services-lifestages ul li:nth-of-type(3n), .midcareer .services-lifestages ul li:nth-of-type(3n) {
      margin-right: 0; } }

.education h1.page-heading {
  margin-bottom: 20px;
  padding-top: 52px;
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .education h1.page-heading {
      text-align: left; } }

.education .page-info {
  text-align: center; }

.education .additional-info {
  text-align: center; }

.education figure {
  margin-bottom: 40px; }
  .education figure img {
    height: auto;
    margin: 0 auto;
    max-width: 480px;
    width: 100%; }

.education article p {
  margin-bottom: 45px;
  padding: 0 5%; }

.education .education-links {
  padding-bottom: 50px; }
  .education .education-links li {
    margin-bottom: 30px; }
    .education .education-links li:last-of-type {
      margin-bottom: 0; }
  .education .education-links a {
    color: #2892d1;
    font-size: 1.2rem;
    text-decoration: underline; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .education .education-links a {
        font-size: 1.2rem; } }
    @media screen and (min-width: 60rem) {
      .education .education-links a {
        font-size: 1.5rem; } }

.education .custom-content .container {
  padding: 0; }

.education .in-the-news {
  padding-bottom: 23px;
  text-align: center; }
  @media screen and (min-width: 48rem) {
    .education .in-the-news {
      text-align: left; } }
  .education .in-the-news h1 {
    font-size: 1.4rem;
    margin-bottom: 15px; }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .education .in-the-news h1 {
        font-size: 1.6rem; } }
    @media screen and (min-width: 60rem) {
      .education .in-the-news h1 {
        font-size: 2.2rem; } }

.contact-form {
  background: #f8f0e8;
  padding: 100px 0 120px 0;
  position: relative; }
  .contact-form h1 {
    margin-bottom: 60px; }
  @media screen and (min-width: 48rem) {
    .contact-form .company-info {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%;
      padding-bottom: 50px; }
      .contact-form .company-info:last-child {
        margin-right: 0; } }
  .contact-form .company-info p {
    color: #525355;
    font-size: 1.2rem;
    margin-bottom: 30px; }
  .contact-form .company-info a {
    color: #525355;
    font-size: 1.2rem; }
    .contact-form .company-info a:hover {
      text-decoration: underline; }
  .contact-form .company-info p.telephone {
    margin: 0; }
  .contact-form .company-info p.fax {
    margin: 0; }
  .contact-form .company-info p.email {
    margin: 0; }
  .contact-form .company-info #map {
    background: #ccc;
    height: 270px;
    margin: 50px 0 60px 0;
    width: 100%; }
    @media screen and (min-width: 48rem) {
      .contact-form .company-info #map {
        margin: 30px 0 50px 0; } }
    @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
      .contact-form .company-info #map {
        width: 350px; } }
    @media screen and (min-width: 60rem) {
      .contact-form .company-info #map {
        width: 85%;
        height: 300px; } }
    .contact-form .company-info #map a {
      font-size: 0.65rem; }
  @media screen and (min-width: 48rem) {
    .contact-form .form-container {
      float: left;
      display: block;
      margin-right: 2.3576515979%;
      width: 48.821174201%;
      margin-right: 0; }
      .contact-form .form-container:last-child {
        margin-right: 0; } }
  .contact-form label {
    color: #134979;
    font-size: 1.1rem;
    margin: 0 0 10px 0; }
  .contact-form input {
    border: 2px solid #ddd;
    height: 45px;
    margin: 0 0 25px 0;
    padding: 5px 10px;
    transition: 0.3s; }
    .contact-form input:-webkit-autofill, .contact-form input:-webkit-autofill:hover, .contact-form input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
      -webkit-text-fill-color: #525355 !important; }
    .contact-form input:focus {
      border: 2px solid #134979;
      box-shadow: none; }
  .contact-form input[type='submit'] {
    margin-bottom: 0; }
  .contact-form textarea {
    border: 2px solid #ddd;
    height: 140px;
    margin: 0 0 20px 0;
    resize: none; }
    .contact-form textarea:focus {
      border: 2px solid #134979;
      box-shadow: none; }
  .contact-form #sent {
    color: #41ad49;
    font-weight: 600;
    letter-spacing: .5px;
    margin: 32px 0 0 32px;
    padding: 2px 0 10px;
    position: relative;
    text-align: center;
    transition: 0.3s; }
    @media screen and (min-width: 48rem) {
      .contact-form #sent {
        text-align: left; } }
    .contact-form #sent::before {
      position: absolute;
      top: -3px;
      left: 32px;
      background: transparent url("../images/success_check.png") center center no-repeat;
      content: '';
      display: inline-block;
      height: 32px;
      width: 24px; }
      @media screen and (min-width: 48rem) {
        .contact-form #sent::before {
          position: absolute;
          top: -3px;
          left: -32px; } }
    .contact-form #sent p {
      font-size: 1.1rem; }
  .contact-form .error {
    color: #ca0614;
    font-size: 0.9rem;
    font-style: italic;
    font-weight: 400;
    margin: -15px 0 25px; }
  .contact-form .g-recaptcha {
    margin-bottom: 20px; }
  .contact-form .field-submit {
    padding: 10px 0 0 0;
    text-align: center; }
    @media screen and (min-width: 48rem) {
      .contact-form .field-submit {
        padding: 20px 0 0 0;
        text-align: left; } }
  .contact-form input#honeyBee {
    display: none; }

/* Modules
*************/
.blue-button {
  background: #134979;
  border: 2px solid #ffffff;
  color: #ffffff;
  outline: 0;
  transition: 0.3s; }
  @media screen and (min-width: 48rem) and (max-width: 59.94rem) {
    .blue-button {
      font-size: 1.2rem; } }
  .blue-button:hover {
    background: #103c63;
    border-radius: 10px;
    color: #ffffff; }
  .blue-button:active, .blue-button:focus {
    background: #134979;
    color: #ffffff; }

.footer-homepage .newsletter input.blue-button {
  width: auto;
  border: 2px solid #fff;
  border-radius: 3px;
  height: auto;
  font-size: 1.5rem;
  font-weight: normal;
  display: inline-block;
  padding: 8px 17px 9px; }

/* Plugins
*************/
